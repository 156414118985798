<template>
     <div class="mod2 flex-col">

          <div @click="seeDetail">
               <div class="group2 flex_row_center_center">
                    <div class="outer5 flex_row_between_center">
                         <span class="paragraph1">
                              {{ info.title }}
                         </span>
                         <div class="outer6 flex-col justify-between">
                              <el-tooltip class="box-item" effect="dark" :content="L['已获得报价数']" placement="bottom">
                                   <div class="mod3 flex-row justify-between">
                                        <img class="icon2" src="@/assets/inquiry/inq_icon1.png" />
                                        <span class="word6">{{ info.quoteNum }}</span>
                                   </div>
                              </el-tooltip>
                         </div>
                    </div>
               </div>
               <div class="group3 flex-row">
                    <img class="pic1" src="@/assets/inquiry/inq_icon2.png" />
                    <div>
                         <span class="paragraph2">
                              {{ info.spec }}
                         </span>
                         <span class="txt5">{{ L['等共'] }}{{ info.productNum }}{{ L['件'] }}</span>
                    </div>

               </div>
               <div class="leo1">
                    <div class="leo2">
                         <div class="group4">
                              <span class="word8">{{ L['商机金额：'] }}</span>
                              <span class="info4">{{ L['￥'] }}{{ info.expectedAmountMin }}&nbsp;—&nbsp;{{ L['￥'] }}{{
                                   info.expectedAmountMax }}</span>
                         </div>
                         <div class="group5" v-if="true">
                              <span class="word9">{{ L['截止时间：'] }}</span>
                              <span class="word10">{{ info.priceEndTime }}</span>
                         </div>
                         <div class="group5" v-else>
                              <span class="word9">{{ L['截止时间：'] }}</span>
                              <span class="leo3">
                                   <span class="leo3_1">24</span>
                                   <span class="leo3_2">:</span>
                                   <span class="leo3_1">00</span>
                                   <span class="leo3_2">:</span>
                                   <span class="leo3_1">00</span>
                              </span>
                         </div>
                         <div class="group6 flex-row" :class="{ mosaic: info.quoteState == 1 }">
                              <div class="mod5">
                                   <span class="info5">{{ L['联系人：'] }}</span>
                                   <span class="word11">{{ info.quoteState == 1 ? 'xxxxxxx' : info.receiverName }}</span>
                              </div>
                              <div class="mod6">
                                   <span class="txt6">&nbsp;{{ L['电话：'] }}</span>
                                   <span class="word12">{{ info.quoteState == 1 ? 'xxxxxxx' : info.receiverMobile }}</span>
                              </div>
                         </div>
                    </div>
               </div>

               <div class="group7 flex_row_between_center">
                    <div class="flex-row">
                         <img class="label3" src="@/assets/inquiry/inq_icon3.png" />
                         <span class="word13">{{ info.receiverAddress }}</span>
                    </div>

                    <div class="box2 flex_row_center_center" @click.stop="iniQuo" v-if="supplierCheck.pass"><span
                              class="txt7">{{ info.quoteState == 1 ? L['去报价'] : L['修改报价'] }}</span>
                    </div>
               </div>
          </div>



          <el-dialog v-model="dialog" width="532px">
               <div class="mod1 flex-col">

                    <div>
                         <div class="bd1 flex-row justify-between">
                              <span class="word1">{{ L['提示'] }}</span>
                              <img class="label1" referrerpolicy="no-referrer" src="@/assets/inquiry/inq_icon11.png"
                                   @click="dialog = false" />
                         </div>
                         <div class="bd2 flex-row">
                              <span class="word2">{{ L['暂不满足询价功能使用条件。满足以下条件后方可使用。'] }}</span>
                         </div>


                         <div class="bd4 flex_column_center_center">
                              <div class="bd3 flex_row_between_center"
                                   v-for="({ description, pass }, index) in inquiryCondition.conditions" :key="index">
                                   <div class="flex-row">
                                        <div class="layer1 flex-col"></div>
                                        <span class="info1">{{ description }}</span>
                                   </div>

                                   <div class="flex_row_center_center">
                                        <img class="label2" :src="require(`@/assets/inquiry/inq_icon${pass ? 6 : 7}.png`)" />
                                        <span class="word3">{{ pass ? L['已满足'] : L['未满足'] }}</span>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div class="bd6 flex-row">
                         <div class="mod25 flex-col" @click="dialog = false">{{ L['我已知晓'] }}</div>
                    </div>
               </div>
          </el-dialog>
     </div>
</template>

<script>
import { reactive, getCurrentInstance, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
     props: {
          info: {
               type: Object,
               default: () => { }
          }
     },
     setup(props, { emit }) {
          const store = useStore()
          const dialog = ref(false)
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const router = useRouter()
          const supplierCheck = ref(store.state.supplierCheck)
          const inquiryCondition = reactive({
               conditions: [],
               pass: ''
          })



          const iniQuo = () => {

               if (props.info.quoteState == 1) {
                    conditionCheck()
               } else {
                    emit('openDialog', props.info.quoteId, props.info.quoteState)
               }

          }

          const seeDetail = () => {
               router.push({
                    path: '/inquiry/supplier/inq_info',
                    query: {
                         inquireId: props.info.inquireId
                    }
               })
          }

          //询价使用条件查询
          const conditionCheck = () => {
               proxy.$get('v3/business/front/supplierInquire/conditionCheck').then(res => {
                    if (res.state == 200) {
                         inquiryCondition.conditions = res.data.conditions
                         inquiryCondition.pass = res.data.pass
                         if (!inquiryCondition.pass) {
                              setTimeout(() => {
                                   dialog.value = true
                              }, 100)
                         } else {
                              emit('openDialog', props.info.inquireId, props.info.quoteState)
                              dialog.value = false
                         }
                    }
               })
          }

          return {
               iniQuo,
               inquiryCondition,
               seeDetail,
               dialog,
               supplierCheck,
               L
          }
     }
}
</script>

<style lang="scss">
.mosaic {

     .word11,
     .word12 {
          color: transparent;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
     }
}


.mod2 {
     width: 370px;
     height: 303px;
     box-shadow: 0px 0px 10px 0px rgb(120 122 129 / 20%);
     margin-bottom: 30px;
     margin-right: 22px;

     &:nth-child(3n) {
          margin-right: 0
     }
}

.group2 {
     background-color: rgba(247, 248, 251, 1);
     height: 60px;
     width: 370px;
}

.outer5 {
     padding: 0 15px;
     width: 100%;
}

.leo1 {
     padding: 0 18px;

     .leo2 {
          padding-bottom: 15px;
          border-bottom: 1px solid #F7F8FB;
     }
}

.leo3 {
     height: 20px;

     .leo3_1 {
          background-color: rgba(239, 237, 237, 1);
          border-radius: 2px;
          width: 26px;
          height: 26px;
          color: rgba(255, 0, 0, 1);
          font-size: 14px;
     }

     .leo3_2 {
          color: rgba(255, 0, 0, 1);
          margin: 0 3px;
     }
}

.paragraph1 {
     color: rgba(51, 51, 51, 1);
     font-size: 16px;
     word-break: break-all;
     line-height: 20px;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 2;
     overflow: hidden;
}

.outer6 {}

.mod3 {
     display: flex;
     align-items: center;
     margin-left: 10px;
}

.icon2 {
     width: 18px;
     height: 18px;
}

.word6 {
     color: rgba(51, 51, 51, 1);
     font-size: 12px;
     white-space: nowrap;
     display: block;
     margin-left: 3px;
     margin-top: 3px;
}

.word7 {
     width: 61px;
     height: 11px;

     color: rgba(255, 255, 255, 1);
     font-size: 12px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 12px;
     display: block;
     margin: 12px 0 0 8px;
}


.group3 {
     width: 339px;
     margin: 14px 0 0 15px;
     border-bottom: 1px solid #F7F8FB;
     padding-bottom: 10px;
}

.pic1 {
     width: 54px;
     height: 54px;
}

.paragraph2 {
     color: rgba(51, 51, 51, 1);
     font-size: 13px;
     line-height: 24px;
     margin: 0px 0 0 12px;
     word-break: break-all;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 2;
     overflow: hidden;
}

.txt5 {

     color: rgba(149, 149, 149, 1);
     font-size: 12px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 12px;
     display: block;
     margin-left: 12px;
}

.img2 {
     width: 340px;
     height: 1px;
     margin: 15px 0 0 15px;
}

.group4 {

     font-size: 0;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 13px;
     margin: 13px 0 0 0px;
}

.word8 {
     width: 177px;
     height: 12px;

     color: rgba(51, 51, 51, 1);
     font-size: 13px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 13px;
}

.info4 {
     width: 177px;
     height: 12px;

     color: rgba(255, 0, 0, 1);
     font-size: 13px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 13px;
}

.group5 {


     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 14px;
     margin: 10px 0 0 0px;
}

.word9 {
     width: 180px;
     height: 14px;

     color: rgba(51, 51, 51, 1);
     font-size: 13px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 14px;
}

.word10 {
     width: 180px;
     height: 14px;

     color: rgba(102, 102, 102, 1);
     font-size: 13px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 14px;
}

.group6 {
     margin: 10px 0 0 0px;
}

.mod5 {

     font-size: 0;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 13px;
     margin-top: 3px;
}

.info5 {

     color: rgba(51, 51, 51, 1);
     font-size: 13px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 13px;
}

.word11 {
     width: 84px;
     height: 12px;

     color: rgba(102, 102, 102, 1);
     font-size: 13px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 13px;
}

.label2 {
     width: 1px;
     height: 18px;
     margin-left: 88px;
}

.mod6 {
     width: 124px;
     height: 12px;

     font-size: 0;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 13px;
     margin: 3px 0 0 33px;
}

.txt6 {
     width: 124px;
     height: 12px;

     color: rgba(51, 51, 51, 1);
     font-size: 13px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 13px;
}

.word12 {
     width: 124px;
     height: 12px;

     color: rgba(102, 102, 102, 1);
     font-size: 13px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     white-space: nowrap;
     line-height: 13px;
}

.img3 {
     width: 340px;
     height: 1px;
     margin: 17px 0 0 15px;
}

.group7 {
     margin: 12px 0 0 14px;
}

.label3 {
     width: 14px;
     height: 19px;
}

.word13 {

     color: rgba(51, 51, 51, 1);
     font-size: 12px;
     font-family: SourceHanSansCN-Regular;
     text-align: left;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 2;
     overflow: hidden;
     word-break: break-all;
     line-height: 12px;
     margin: 4px 0 0 10px;
}

.box2 {
     background-color: rgba(47, 123, 255, 1);
     border-radius: 2px;
     height: 34px;
     width: 104px;
     margin: 3px 10px 0 0px;
}

.txt7 {

     color: rgba(255, 255, 255, 1);
     font-size: 14px;
     font-family: SourceHanSansCN-Regular;
     white-space: nowrap;
     display: block;
     cursor: pointer;
}

.mod1 {
     background-color: rgba(255, 255, 255, 1);
     border-radius: 6px;
     min-height: 345px;
     width: 532px;
     justify-content: space-between;

     .bd1 {
          height: 34px;
          margin: 20px 0 0 243px;
          justify-content: space-between;

          .word1 {
               width: 45px;
               height: 23px;

               color: rgba(0, 0, 0, 1);
               font-size: 22px;
               font-family: MicrosoftYaHei-Bold;
               text-align: left;
               white-space: nowrap;
               line-height: 23px;
               margin-top: 11px;
               display: block;
          }

          .label1 {
               width: 16px;
               height: 16px;
               margin-right: 16px;
               cursor: pointer;
          }
     }

     .bd2 {
          width: 404px;
          height: 16px;
          margin: 31px 0 0 59px;

          .word2 {
               width: 404px;
               height: 16px;

               color: rgba(18, 18, 18, 1);
               font-size: 16px;
               font-family: MicrosoftYaHei;
               text-align: center;
               white-space: nowrap;
               line-height: 16px;
               display: block;
          }
     }

     .bd4 {
          margin: 45px 0 0 0px;

     }


     .bd3 {
          width: 345px;
          height: 18px;
          margin-bottom: 20px;

          .layer1 {
               background-color: rgba(102, 102, 102, 1);
               border-radius: 50%;
               width: 6px;
               height: 6px;
               margin-top: 6px;
          }

          .info1 {
               width: 57px;
               height: 13px;

               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               font-family: MicrosoftYaHei;
               text-align: left;
               white-space: nowrap;
               line-height: 14px;
               display: block;
               margin: 3px 0 0 11px;
          }

          .label2 {
               width: 18px;
               height: 18px;
          }

          .word3 {
               width: 43px;
               height: 12px;

               color: rgba(102, 102, 102, 1);
               font-size: 14px;
               font-family: MicrosoftYaHei;
               text-align: left;
               white-space: nowrap;
               line-height: 14px;
               display: block;
               margin: 0px 0 0 6px;
          }
     }


     .bd6 {
          width: 127px;
          height: 36px;
          margin: 48px 0 28px 203px;
          cursor: pointer;

          .mod25 {
               background-color: rgba(8, 113, 243, 1);
               border-radius: 4px;
               height: 36px;
               width: 127px;
               text-align: center;
               line-height: 36px;
               color: rgba(255, 255, 255, 1);
               font-size: 16px;
               font-family: MicrosoftYaHei;
          }
     }
}
</style>