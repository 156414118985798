<template>
     <div class="inquiry_index">
          <div class="inquiry_header">
               <div class="header_bg" :style="{ backgroundImage: `url(${header_bg})` }"></div>
          </div>
          <div class="inquiry_z">
               <div class="inquiry_main">
                    <div class="outer3 flex_row_center_center inquiry_query">
                         <span class="txt1">{{ L['询盘标题'] }}:</span>
                         <div class="bd1 flex-col">
                              <val-input v-model="title" type="text" maxlength="50"></val-input>
                         </div>
                         <span class="txt2">{{ L['商机金额'] }}:</span>
                         <div class="bd2 flex-col">
                              <val-input type="number" v-model="expectedAmountMin" min="0" max="9999999" mode="float"
                                   :placeholder="L['最小值']"></val-input>
                         </div>
                         <span class="word2">~</span>
                         <div class="bd3 flex-col">
                              <val-input type="number" v-model="expectedAmountMax" min="0" max="9999999" mode="float"
                                   :placeholder="L['最大值']"></val-input>
                         </div>

                         <span class="txt4">{{ L['收货地区'] }}:</span>
                         <div class="bd4 flex-col">
                              <el-cascader ref="cascaderAddr" v-model="receiverAddress" :options="areaDataArray"
                                   :placeholder="L['请选择所在地区']" :props="cascaderProps" size="small" clearable>
                              </el-cascader>
                         </div>
                         <span class="word3">{{ L['状态'] }}:</span>
                         <div class="bd5 flex-col">
                              <el-select v-model="quoteState" class="m-2" :placeholder="L['请选择']" size="small">
                                   <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                   </el-option>
                              </el-select>
                         </div>
                         <div class="bd6 flex-col" @click="confirm"><span class="info3">{{ L['搜索'] }}</span></div>
                         <div class="bd7 flex-col" @click="reset"><span class="word5">{{ L['重置'] }}</span></div>
                    </div>
                    <div v-if="quoList.list && quoList.list.length">
                         <div class="inq_list flex_row_start_start">
                              <inqItem v-for="(item, index) in quoList.list" :key="index" @openDialog="openDialog"
                                   :info="item"></inqItem>
                         </div>

                         <!-- 分页 -->
                         <div class="flex_row_center_center sld_pagination">
                              <el-pagination @current-change="handleCurrentChange" v-model:currentPage="quoList.page.current"
                                   :page-size="quoList.page.pageSize" layout="prev, pager, next, jumper"
                                   :total="quoList.page.total" :hide-on-single-page="true">
                              </el-pagination>
                         </div>
                    </div>
                    <div class="inquiry_empty flex_column_center_center" v-else>
                         <img src="@/assets/inquiry/inq_empty.png" alt="">
                         <p>{{ L['暂无询价'] }}</p>
                    </div>

               </div>
          </div>
          <quoteDialog ref="quoteDialog"></quoteDialog>
     </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, toRefs, onMounted } from 'vue'
import inqItem from './components/inq_item.vue'
import { inqUtils } from '@/utils/inquiry_utils.js'
import quoteDialog from './components/quoteDialog.vue'
import valInput from './components/vali-input.vue'
export default {
     components: {
          inqItem,
          quoteDialog,
          valInput
     },
     setup(props) {
          const header_bg = require('@/assets/inquiry/header_bg1.png')
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const value = ref('')
          const { areaDataArray, cascaderProps } = inqUtils();
          const page = reactive({
               pageSize: 6,
               current: 1,
               total: 10
          })

          const param = reactive({
               current: 1,
               pageSize: 6
          })

          const searchDTO = reactive({
               expectedAmountMax: '',
               expectedAmountMin: '',
               quoteState: '',
               title: '',
               receiverAddress: []
          })

          const quoList = reactive({
               list: [],
               page: {}
          })

          const options = [
               {
                    value: 1,
                    label: L['未报价'],
               },
               {
                    value: 2,
                    label: L['已报价'],
               }
          ]

          const openDialog = (id, state) => {
               proxy.$refs.quoteDialog.initDTO()
               if (state == 1) {
                    proxy.$refs.quoteDialog.setInquireId(id)
               } else {
                    proxy.$refs.quoteDialog.getQuoInfo(id)
               }

               proxy.$refs.quoteDialog.dialogVisible = true
          }


          const handleCurrentChange = (e) => {
               param.current = e
               getQuoList()
          }

          const getQuoList = () => {
               proxy.$get('v3/business/front/supplierInquire/list', param).then(res => {
                    if (res.state == 200) {
                         quoList.list = res.data.list
                         quoList.page = res.data.pagination
                    }
               })
          }

          const confirm = () => {
               Object.keys(searchDTO).forEach(item => {
                    if (item == 'receiverAddress') {
                         if (searchDTO[item].length) {
                              param[item] = searchDTO[item].join('/')
                         } else {
                              delete param[item]
                         }
                    } else {
                         if (searchDTO[item]) {
                              param[item] = searchDTO[item]
                         } else {
                              delete param[item]
                         }
                    }
               })
               getQuoList()
          }

          const reset = () => {
               Object.keys(searchDTO).forEach(item => {
                    delete param[item]
                    if (item == 'receiverAddress') {
                         searchDTO[item] = []
                    } else {
                         searchDTO[item] = ''
                    }
               })
               getQuoList()
          }

          onMounted(() => {
               getQuoList()
          })


          return {
               header_bg,
               value,
               options,
               page,
               openDialog,
               ...toRefs(searchDTO),
               areaDataArray,
               cascaderProps,
               quoList,
               confirm,
               reset,
               handleCurrentChange,
               L,
          }
     }
}
</script>

<style lang="scss">
input::placeholder {
     font-size: 12px;
     font-family: Microsoft YaHei;
     font-weight: 400;
     color: #c0c4cc;
}

::-webkit-input-placeholder {
     font-size: 12px;
     font-family: Microsoft YaHei;
     font-weight: 400;
     color: #c0c4cc;
}

/* 使用webkit内核的浏览器 */
:-moz-placeholder {
     font-size: 12px;
     font-family: Microsoft YaHei;
     font-weight: 400;
     color: #c0c4cc;
}

/* Firefox版本19+ */
:-ms-input-placeholder {
     font-size: 12px;
     font-family: Microsoft YaHei;
     font-weight: 400;
     color: #c0c4cc;
}

/* IE浏览器 */

/*添加css样式*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
}

input[type="number"] {
     -moz-appearance: textfield;
}



.inquiry_header {
     position: relative;
     height: 300px;
     width: 100%;

     .header_bg {
          background-position: center center;
          width: 100%;
          height: 301px;
          position: absolute;
          left: 0;
          top: 0;
          /* margin-left: -960px; */
          background-repeat: no-repeat;
          background-size: cover;
     }
}

.inquiry_z {
     background: $colorJ;
     padding-top: 20px;
     width: 100%;
     padding-bottom: 30px;

     .inquiry_main {
          width: 1200px;
          background: #FFFFFF;
          margin: 0 auto;
          padding: 20px;

          .outer3 {
               .txt1 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    white-space: nowrap;
               }

               .bd1 {
                    background-color: rgba(247, 248, 250, 1);
                    border-radius: 3px;
                    width: 170px;
                    height: 36px;
                    // border: 1px solid rgba(201, 201, 201, 1);
                    margin-left: 10px;
                    // padding: 0 10px;

                    input {
                         height: 36px;
                         border: none;
                         outline: none;
                         background: transparent;
                    }
               }

               .txt2 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    margin-left: 15px;
                    white-space: nowrap;
               }

               .bd2 {
                    background-color: rgba(247, 248, 250, 1);
                    border-radius: 3px;
                    height: 36px;
                    // border: 1px solid rgba(201, 201, 201, 1);
                    margin-left: 12px;
                    width: 76px;
               }

               .bd3 {
                    background-color: rgba(247, 248, 250, 1);
                    border-radius: 3px;
                    height: 36px;
                    // border: 1px solid rgba(201, 201, 201, 1);
                    margin-left: 6px;
                    width: 76px;
               }

               .bd2,
               .bd3 {
                    // padding: 0 10px;

                    input {
                         height: 36px;
                         border: none;
                         outline: none;
                         background: transparent;
                    }

               }


               .info1 {
                    width: 37px;
                    height: 11px;
                    color: rgba(153, 153, 153, 1);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 12px;
                    display: block;
                    margin: 12px 0 0 9px;
               }

               .word2 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 20px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    display: block;
                    margin-left: 6px;
               }


               .txt3 {
                    width: 37px;
                    height: 12px;
                    color: rgba(153, 153, 153, 1);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 12px;
                    display: block;
                    margin: 11px 0 0 11px;
               }

               .txt4 {
                    white-space: nowrap;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    margin-left: 12px;
               }

               .bd4 {
                    background-color: rgba(247, 248, 250, 1);
                    border-radius: 3px;
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    margin-left: 14px;
                    width: 228px;
               }

               .bd4,
               .bd5 {

                    .el-select,
                    .el-input__inner {
                         height: 33px;
                         line-height: 33px;
                         border: none;
                         background: transparent;

                         &:hover {
                              border: none
                         }
                    }

                    .el-input__icon {
                         line-height: 33px;
                    }
               }

               .layer1 {
                    width: 207px;
                    height: 11px;
                    margin: 12px 0 0 10px;
               }

               .info2 {
                    width: 37px;
                    height: 11px;
                    color: rgba(153, 153, 153, 1);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 12px;
                    display: block;
               }

               .icon1 {
                    width: 13px;
                    height: 7px;
                    margin-top: 3px;
               }

               .word3 {

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    display: block;
                    margin-left: 21px;
               }

               .bd5 {
                    background-color: rgba(247, 248, 250, 1);
                    border-radius: 3px;
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    margin-left: 11px;
                    width: 100px;
               }

               .group1 {
                    width: 83px;
                    height: 11px;
                    margin: 12px 0 0 11px;
               }

               .word4 {
                    width: 37px;
                    height: 11px;
                    color: rgba(153, 153, 153, 1);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 12px;
                    display: block;
               }

               .label1 {
                    width: 13px;
                    height: 7px;
                    margin-top: 3px;
               }

               .bd6 {
                    background-color: rgba(40, 95, 222, 1);
                    border-radius: 4px;
                    height: 32px;
                    width: 68px;
                    margin: 1px 0 0 23px;
               }

               .info3 {
                    width: 28px;
                    height: 13px;
                    color: rgba(255, 255, 255, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 9px 0 0 19px;
                    cursor: pointer;
               }

               .bd7 {
                    border-radius: 4px;
                    height: 32px;
                    border: 1px solid rgba(40, 95, 222, 1);
                    width: 68px;
                    margin: 1px 0 0 10px;
               }

               .word5 {
                    width: 29px;
                    height: 13px;
                    color: rgba(40, 95, 222, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 9px 0 0 19px;
                    cursor: pointer;
               }
          }

          .inq_list {
               flex-wrap: wrap;
               margin-top: 41px;
          }

     }
}

.inquiry_empty {

     padding-top: 140px;
     padding-bottom: 200px;

     img {
          width: 120px;
          height: 119px;
     }

     p {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-top: 34px;
     }
}



.inquiry_index {}
</style>